<template>
  <div>
    <el-table border
              stripe
              :data="tableData"
              highlight-current-row
              :row-key="rowKey"
              :max-height="$store.state.tableHeight + height"
              :tree-props="config"
              ref="myTableRefs"
              style="width: 100%">
      <!-- :show-overflow-tooltip="item.showToopic" -->
      <el-table-column v-for="(item,index) in tableColumns"
                       :prop="item.prop"
                       :key="index"
                       :show-overflow-tooltip="item.label != '操作'"
                       v-if="item.isShow"
                       :width="item.width"
                       :align="item.align"
                       :label="item.label">
        <template slot="header">
          <i class="el-icon-setting"
             slot="header"
             v-if="item.label == '操作'"
             @click="showController"></i>
          {{item.label}}
        </template>
        <template slot-scope="{row,$index}">
          <div v-if="item.label == '操作'">
            <div v-if="item.type == 'img'">
              <div v-for="(opItem,opIndex) in item.btns"
                   @click="clickEvent(row,item,opIndex,$index)"
                   
                   class="opertion_image_warp"
                   :key="opIndex">
                <el-tooltip effect="dark"
                            :content="opItem.content"
                             v-if="opItem.isPd?$parent.getBtnStatus(opItem, row) : true"
                            placement="top-start">
                  <img :src="imgs[opItem.type]"
                       class="operyion_img">
                </el-tooltip>
              </div>

            </div>
            <div v-else
                 v-for="(opItem,opIndex) in item.btns"
                 @click="clickEvent(row,item,opIndex,$index)"
                 :key="opIndex"
                 :class="opItem.class">
              {{opItem.name}}
            </div>
          </div>
          <!-- 当不是操作行的时候显示具体内容 -->
          <!-- 将所有的状态都交给主页面做处理 -->
          <div v-else>
            <div v-if="item.isStatus"
                 :class="$parent.getClass(item.prop,row[item.prop])">
              {{$parent.getValue(item.prop,row[item.prop])}}
            </div>
            <div v-else
                 class="text_warp">
              {{row[item.prop]}}
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div>

      <!-- <TreeTable :stripe="true"
                 :border="true"
                 :data="tableData"
                 v-if="tableData.length"
                 :tree-type="true"
                 :columns="tableColumns"
                 :children-prop="config.children"
                 :expand-type="false"
                 :show-row-hover="true"
                 :selection-type="false">
        <template scope="{row}"
                  v-for="item in tableColumns.filter(item=>item.isStatus)"
                  :slot="item.template">
          {{item.template}}
         
        </template>
        <template slot="opt"
                  scope="{row}">
          <div v-if="tableColumns[tableColumns.length - 1].btnType == 'img'">
            <div v-for="(opItem,opIndex) in tableColumns[tableColumns.length - 1].btns"
                 @click="clickEvent(row,tableColumns[tableColumns.length - 1],opIndex)"
                 class="opertion_image_warp"
                 :key="opIndex">
              <el-tooltip effect="dark"
                          :content="opItem.content"
                          placement="top-start">
                <img :src="imgs[opItem.type]"
                     class="operyion_img">
              </el-tooltip>
            </div>
          </div>
          <div v-else
               v-for="(opItem,opIndex) in tableColumns[tableColumns.length - 1].btns"
               @click="clickEvent(row,tableColumns[tableColumns.length - 1],opIndex)"
               :key="opIndex"
               :class="opItem.class">
            {{tableColumns.filter(item=>item.isStatus)}}
            {{opItem.name}}
          </div>
        </template>
      </TreeTable> -->
    </div>
    <Shuttle ref="ShuttleRef" />
  </div>
</template>

<script>
import Shuttle from './index.vue'
export default {
  data () {
    return {
      tableColumns: [],
      pageName: '',
      imgs: {
        del: require('@/assets/icons/icon-删除@2x.png'),
        edit: require('@/assets/icons/icon-修改@2x.png'),
        reset: require('@/assets/icons/icon-重置密码.png'),
        look: require('@/assets/icons/icon-查看@2x.png'),
        fabu: require('@/assets/icons/发布.png'),
        teacher: require('@/assets/classes/teacher.png'),
        student: require('@/assets/classes/student.png'),
        lookStudent: require('@/assets/classes/icon-look.png'),
        relation: require('@/assets/icons/icon-关联知识点@2x.png'),
        recover: require('@/assets/icons/恢复.png'),
      }
    }
  },
  props: {
    tableData: {
      type: Array,
      default: []
    },
    rowKey: {
      type: String,
      default: ''
    },
    height: {
      type: Number,
      default: 30
    },
    config: {
      type: Object,
      // 对象 数组类型必须使用工厂函数
      default () {
        return {}
      }
    }
  },
  components: {
    Shuttle
  },
  methods: {
    // row传的是当前行数据，item传的是定义columns的那一行，opindex传的是操作数组的index
    clickEvent (row, item, opIndex, index) {
      this.$parent[item.btns[opIndex].event](row, index)
    },
    showController () {
      this.$refs.ShuttleRef.columns = JSON.parse(JSON.stringify(this.tableColumns))
      this.$refs.ShuttleRef.dialogVisible = true
    },
    setColumns (arr) {
      // 查询浏览器是否存储该页列表项
      if (window.localStorage.getItem(this.pageName)) {
        this.tableColumns = JSON.parse(window.localStorage.getItem(this.pageName))
      } else {
        this.tableColumns = arr.map(item => {
          item.isShow = true
          return item
        })
        // window.localStorage.setItem(this.pageName, JSON.stringify(this.tableColumns))
      }
    },
    getColumns (arr) {
      this.tableColumns = arr
      window.localStorage.setItem(this.pageName, JSON.stringify(arr))
      this.$forceUpdate()
    },
    setCurrentRow (data) {
      this.$refs.myTableRefs.setCurrentRow(data)
    }

  }
}
</script>

<style lang="scss" scoped>
.opertion_image_warp {
  display: inline-block;
  .operyion_img {
    cursor: pointer;
  }
}

.el-icon-setting {
  cursor: pointer;
}
// ::v-deep [data-v-f68e6516] .cell .el-table__expand-icon {
//   position: absolute;
//   margin-left: -50px;
// }
.text_warp {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  /*禁止换行显示*/
  white-space: nowrap;
}

::v-deep .el-table__row {
  .is-center {
    &:nth-child(1) {
      flex-wrap: nowrap;
      .el-tooltip {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        .el-table__expand-icon {
          margin-right: 20px;
          width: 0;
          height: 0;
          border-top: 7px solid transparent;
          border-left: 13px solid black;
          border-bottom: 7px solid transparent;
          .el-icon-arrow-right:before {
            content: "";
          }
          // [class*=" el-icon-"],
          // [class^="el-icon-"] {
          //   content: "";
          //   padding: 5px;
          //   background: #559de7;
          //   border-radius: 50%;
          //   color: white;
          // }
        }
      }
    }
  }
}
</style>